@import 'npm:@blueprintjs/core/lib/css/blueprint.css';
@import 'npm:@blueprintjs/icons/lib/css/blueprint-icons.css';
@import 'npm:@blueprintjs/popover2/lib/css/blueprint-popover2.css';
@import 'npm:normalize.css/normalize.css';
html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  min-width: 100%;
  width: fit-content;
}

/*
  HACK:
  if removed, it will make the the EditButton
  in menu-edit-line to look like a normal button
*/
.menu-edit-line__SubMenu > span > span:first-child {
  width: 100% !important;
}

/* HACK:  */
/* .bp3-dialog {
  width: auto;
} */
